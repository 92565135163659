html {
  //   font-size: 16px;
}
.landing_page {
  background-color: #fff8f5;
  color: #1a2f54;
  position: relative;
  .landing_header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff8f5;
    transition: all 0.5s;
    .navbar {
      padding: 10px 0;
      .navbar-brand {
        img {
          width: 100px;
          transition: 0.5s;
        }
      }
      .nav-item {
        border-radius: 10px;
        .nav-link {
          padding: 5px 15px;
          color: #2c2c2c;
          font-weight: 500;
          font-size: 16px;
          transition: all 0.3s;
          text-transform: uppercase;
        }
        &:hover {
          background: #ffaba2;
          .nav-link {
            color: #fff;
            // font-weight: 600;
          }
        }
      }
    }
  }
  .navScroll {
    background-color: #d6c6bff2;
    z-index: 9999;
    .navbar {
      .navbar-brand {
        img {
          width: 60px;
          height: 40px;
          transition: 0.5s;
        }
      }
    }
  }
  .page_btn {
    font-size: 1rem;
    background-color: #ffaba2;
    color: #fff;
    border-radius: 25px;
    padding: 7px 20px;
    display: inline-block;
  }
  .hero_outter {
    max-width: 50%;
    padding: 7.3rem 0 5rem;
    // background-color: rebeccapurple;
    h2 {
      font-weight: 600;
      font-size: 2rem;
    }
    p {
      margin: 1.2rem 0;
    }
  }
  .features {
    background-color: #fff8f5;
    padding: 2rem 0 5rem;
    .single_box {
      display: flex;
      align-items: center;
      background-color: #fff;
      width: fit-content;
      padding: 6px 15px;
      //   border: 1px solid transparent;
      border-radius: 25px;
      p {
        margin-left: 10px;
        font-weight: 500;
      }
      .icon {
        border: 1px solid #1a2f54;
        padding: 7px;
        border-radius: 50%;
        svg {
          font-size: 25px;
          color: #ffaba2;
        }
      }
    }
  }
  .services {
    background-color: #fff8f5;
    .services_inner {
      padding: 2.5rem 0 4rem;
      .single_service {
        background-color: #fff8f5;
        p {
          text-align: start;
          margin-bottom: 13px;
          font-weight: 500;
          font-size: 16px;
        }
        img {
          border-radius: 15px;
          aspect-ratio: 15/10;
        }
      }
      .service_bottom {
        margin-top: 2.5rem;
        .next_prev_icons {
          svg {
            color: #ffaba2;
            font-size: 20px;
            cursor: pointer;
          }
          #prev_btn {
            margin-right: 20px;
          }
        }
        .swiper-button-disabled svg {
          color: gainsboro;
        }
      }
    }
  }
  .section_title {
    font-size: 1.7rem;
    font-weight: 600;
    text-align: center;
  }
  .clear_nail {
    background-color: #fff8f5;
    .section_title {
      text-align: left;
      margin-bottom: 1.3rem;
    }
    .img_box {
      position: relative;
      img {
        border-radius: 15px;
        transition: all 0.5s;
      }
    }
    .first_image {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 80%;
      &:hover {
        transform: scale(1.1);
        z-index: 1;
      }
    }
    .second_image {
      position: absolute;
      top: 4rem;
      left: 4rem;
      max-width: 80%;
      &:hover {
        transform: scale(1.2);
      }
    }
    p {
      margin-bottom: 1.3rem;
    }
  }
  .process {
    padding: 3rem 0 3rem;
    background-color: #fff8f5;
    text-align: center;
    .section_title {
      margin-bottom: 2.5rem;
    }
    iframe {
      border-radius: 10px;
      // aspect-ratio: 1;
      width: 100%;
      // height: auto;
    }
  }
  .testimonials {
    background-color: #fff8f5;
    padding-bottom: 3rem;
    .section_title {
      margin-bottom: 2.5rem;
      margin-top: 2.5rem;
    }

    .single_testimonial:nth-child(odd) {
      .review_text_box {
        background: #ffaba2;
        position: relative;
        p {
          color: #fff;
        }
        .quota {
          position: absolute;
          height: 40px;
          width: 40px;
          background: #ffaba2;
          bottom: -17px;
          rotate: 45deg;
          left: 24%;
          border-radius: 5px;
        }
      }
    }
    .single_testimonial {
      .review_text_box {
        background: #fff;
        padding: 40px;
        // height: 146px;
        border-radius: 10px;
      }
      .user_identity {
        margin-top: 20px;
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
        }
        p.name {
          font-weight: 600;
        }
        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-rating {
          font-size: 12px;
          .ant-rate-star:not(:last-child) {
            margin-right: 3px;
          }
          .ant-rate-star-zero{
            .anticon-star{
              color: #a3a1a1;
            }
          }
        }
      }
    }
    .next_prev_icons {
      position: relative;
      .next_prev_icons_inner {
        z-index: 1;
        position: absolute;
        bottom: 5px;
        right: 0;
        svg {
          color: #ffaba2;
          font-size: 20px;
          cursor: pointer;
        }
        #prev_btn {
          margin-right: 20px;
        }
        .swiper-button-disabled svg {
          color: gainsboro;
        }
      }
    }
  }
  .get_touch {
    padding-bottom: 3rem;
    margin-top: 2.5rem;
    .section_title {
      margin-bottom: 2.5rem;
      // justify-content: end;
      // h2{
      //   max-width: 66%;
      // }
    }
    .contact-img {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .input_form {
      text-align: end;
      input {
        // min-width: 320px;
        width: 95%;
        border: none;
        border-radius: 25px;
        padding: 16px 25px;
        margin-bottom: 2rem;
      }
      a {
        // min-width: 320px;
        width: 95%;
        margin: auto;
        display: block;
        text-align: center;
        border-radius: 25px;
        border: none;
        padding: 12px 25px;
        background-color: #ffaba2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .landing_footer {
    margin-top: 2.5rem;
    .upper_section {
      h5 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 0.5rem;
      }
      .footer_logo {
        text-align: end;
        img {
          width: 150px;
        }
      }
    }
    .bottom_section {
      .copyright {
        font-weight: 500;
        font-size: 13px;
      }
      .policy {
        p {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }
}

.services {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .landing_header {
    background-color: #ffaba2 !important;
    .navbar {
      color: #fff;
      .nav-item {
        .nav-link {
          color: #fff !important;
        }
      }
    }
  }
 .landing_page{
  .hero_outter{
    max-width: 100%;
  }
 }
  .testimonials {
    .next_prev_icons_inner {
      bottom: -33px !important;
    }
  }
}

@media (max-width: 768px) {
  .get_touch {
    .section_title {
      margin-top: 2rem;
    }
  }
}
@media (max-width: 576px) {
  .testimonials {
    .user_identity {
      margin-top: 35px !important;
    }
  }
  .clear_nail {
    .section_title {
      margin-top: 40px;
    }
  }
}
