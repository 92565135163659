#appointment-que {
  .que-table {
    .table-row-light {
      background-color: #576b6340;
    }
    .table-row-dark {
      background-color: #fbfbfb;
    }
    .ant-table thead .ant-table-cell {
      background: #2c2c2c;
      text-align: center;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0.7px;
      font-weight: 600;
    }
    tbody{
      .ant-table-cell{
        text-align: center;
      }
    }
  }
  ul {
    li {
      &::marker {
        color: #576b63;
      }
      font-weight: 400;
    }
  }
}
