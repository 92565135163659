$primary-color: #576b63;
#custom-header {
  position: absolute;
  top: 0;
  z-index: 999999;
  width: 100%;
  min-height: 80px;
  background: transparent;
  // opacity: 0.7;
  transition: 0.3s;
  display: flex;
  align-items: center;
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .app-logo {
      img {
        width: 150px;
        height: 50px;
      }
    }
    .app-menu {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li,
        a {
          display: inline-block;
          margin: 0 10px;
          padding: 10px;
          font-weight: 500;
          font-size: 16px;
          color: #fff;
        }
        a {
          &:hover {
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: 600;
            transition: 0.6s;
            background-color: $primary-color;
          }
        }
      }
    }

    i {
      color: #fff;
    }
  }
}

.private-layout {
  .private-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $primary-color;
    .logo {
      img {
        width: 80px;
      }
    }
  }
  .ant-layout-sider,
  .ant-menu-light {
    background-color: $primary-color;
    background: $primary-color;
    color: #fff;
    border-radius: 10px;
  }
}
.profile-dropdown {
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }
}
.sidebar-menu {
  .ant-menu-item {
    padding: 0 10px;
    margin: 12px 10px;
    background-color: #3f544c;
    width: auto;
    border-radius: 10px;
    a {
      color: #fff;
      font-weight: 600;
    }
    &:hover,
    .ant-menu-item-selected {
      background-color: #1c2622;
      color: #fff;
    }
    &::after {
      border: none;
    }
  }

  // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   background-color: #1c2622 !important;
  //   color: #fff;
  // }
  .ant-menu-item-selected {
    background-color: #1c2622 !important;
    color: #fff;
  }
}

.mobile-app-menu {
  ul {
    list-style: none;
    li {
      margin-bottom: 10px;
      color: #000;
      a {
        color: #000;
        font-weight: 500;
      }
    }
  }
}

.mobile-sider {
  .ant-drawer-header {
    border: none;
    .ant-drawer-close {
      color: #fff;
    }
  }
  .ant-drawer-content {
    background: $primary-color;
    .ant-layout {
      background: transparent;
      .ant-menu-light {
        background: transparent;
        border: none;
        color: #fff;
      }
    }
    .ant-layout-sider {
      background: transparent;
    }
  }
}
