.footer {
  // background-color: $primary-color;
  background-color: #8080801a;
  padding: 40px 0;
  .footer-title {
    h6 {
      font-weight: 600;
      color: $primary-color;
      text-transform: uppercase;
    }
    margin-bottom: 15px;
  }
  .footer-icon{
    font-size: 22px;
  }
  ul {
    list-style: none;
    margin: 0;
    margin-left: 0;
    padding-left: 0;
    li {
      padding-left: 0;
      margin-left: 0;
      font-size: 14px;
    }
  }
}


.footer-end {
  background: #786b6b54;
  padding: 20px;
  text-align: center;
  color: #000;
  font-weight: 400;
}
