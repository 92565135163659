//font
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400&display=swap");

// common css
@import "./generic.scss";

// component

@import "./components/header";
@import "./components/auth";
@import "./components/footer";
@import "./components/home";
@import "./components/create-appointment";
@import "./components/appointment-que";
@import "./components/my-profile";
@import "./components/landing_page";

// responsive
@import "./responsive.scss";

body {
  font-family: "Poppins", sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
