#home {
  .hero-section {
    .slider-item {
      min-height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      display: flex;
      justify-content: baseline;
      align-items: flex-end;
      a {
        padding: 10px;
        background: #576b63;
        color: #fff;
        font-size: 16px;
        border-radius: 3px;
        position: relative;
        z-index: 5;
      }
    }
  }
  .waiting-list {
    background-color: #8080801a;
    padding-bottom: 40px;
    .ant-table {
      thead {
        .ant-table-cell {
          background: #2c2c2c;
          text-align: center;
          color: #fff;
          font-size: 16px;
          letter-spacing: 0.7px;
          font-weight: 600;
        }
      }
      tbody{
        .ant-table-cell {
          // background: #2c2c2c;
          text-align: center;
          // color: #fff;
        }
        tr{
          font-weight: 500;
        }
      }
    }
  }
  .get-appointment {
    .description {
      font-size: 16px;
      font-weight: 400;
    }
    .description-poster {
      padding: 10px;
      img {
        width: 100%;
        margin-top: 40px;
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .happy-clients {
    background-color: #4d504fad;
    .review-card {
      border: 1px solid rgb(188, 185, 185);
      padding: 10px;
      border-radius: 10px;
      margin: 0 10px;
      background: #2c2c2c;
      color: #fff;
      .client-avater {
        margin-bottom: 10px;
        img {
          border-radius: 50%;
          width: 154px;
          height: 146px;
          border: 2px solid $primary-color;
        }
      }
      .client-description {
        q {
          font-style: italic;
        }
      }
    }
  }
  .footer {
    // background-color: $primary-color;
    padding: 40px 0;
    .footer-title {
      h6 {
        font-weight: 600;
        color: $primary-color;
        text-transform: uppercase;
      }
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      margin: 0;
      margin-left: 0;
      padding-left: 0;
      li {
        padding-left: 0;
        margin-left: 0;
        font-size: 14px;
      }
    }
  }
}
