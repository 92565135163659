$primary-color: #576b63;

.section-divider {
  margin-bottom: 40px;
}
.no-divider {
  margin-bottom: 0;
}
.section-title {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  h2 {
    position: relative;
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 300;
  }
  .section-underline {
    position: absolute;
    height: 1px;
    background-color: $primary-color;
    width: 100%;
  }
  .white-title {
    color: #fff;
  }
  .white-underline {
    background-color: #fff;
  }
}
.mini-title {
  margin-bottom: 15px;
  h5 {
    color: $primary-color;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    transition: transform 0.2s;
  }
}

.ant-btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  border-radius: 10px;
  color: #fff;
  &:hover {
    background: #3d4a45;
    border-color: #3d4a45;
    border-radius: 10px;
    color: #fff;
  }
  &:focus {
    background: $primary-color;
    border-color: $primary-color;
    border-radius: 10px;
    color: #fff;
  }
}

.common-input {
  margin-bottom: 15px;
  label {
    font-weight: 600;
    font-size: 14px;
    sup {
      color: orangered;
    }
  }
}

.left-menu li a.active {
  background-color: #fff;
  font-weight: 700;
}
.ant-select-item-option-disabled{
  color: grey;
}

