#auth-section {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("/img/slide-2.jpg");
  height: 100vh;
  display: flex;
  align-items: center;
  // .auth-input {
  //   margin-bottom: 15px;
  //   label {
  //     font-weight: 600;
  //     font-size: 14px;
  //     sup {
  //       color: red;
  //     }
  //   }
  // }
  .submit {
  }
}
.auth-input {
  margin-bottom: 15px;
  label {
    font-weight: 600;
    font-size: 14px;
    sup {
      color: red;
    }
  }
  small {
    color: orangered;
  }
}

#email-change-confirmation {
  padding: 150px 0;
  margin-bottom: 50px;
  .pass-check-mark {
    font-size: 80px;
    margin-bottom: 50px;
    color: #52c41a;
  }
  a {
    font-weight: 600;
    font-size: 18px;
  }
  .title {
    h1 {
      font-size: 40px;
      font-weight: 600;
      color: #364776e8;
    }
  }
  .message {
    p {
      text-align: center;
      margin-bottom: 30px;
      color: grey;
      font-size: 16px;
    }
    .black {
      color: #2c2c2c;
    }
  }
  .verify-icon {
    text-align: center;
    .ant-badge-dot {
      width: 12px;
      height: 12px;
      top: 8px;
      // background: green;
    }
    i {
      font-size: 65px;
      color: #6c6b6b;
      position: relative;
      .right-mark {
        color: #52c41a;
        position: absolute;
        font-size: 25px;
        right: -15px;
        top: -5px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .signup-mobile {
    height: unset !important;
    .signup-form {
      margin-bottom: 250px;
      margin-top: 200px;
    }
  }
}
